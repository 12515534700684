import { connect } from 'react-redux';
import GridViewRow from './GridViewRow';
import { isCurrentUserAdmin } from '../../selectors/user';
import getPromotionById from '../../reducers/gridViewPage/getPromotionById';
import {
  CellHighlightOverride,
  Columns,
  Language,
  PercentageColumns,
  PopOverDirection,
} from '../../constants';
import { getStoreRegionErrors } from '../../helpers/gridView';

const isPromotionRelatedToActiveCell = (state, activeCell, promotion) => {
  if (!activeCell || !promotion) {
    return false;
  }

  const activePromotion = getPromotionById(state.GridViewPage, activeCell.rowId);
  if (!activePromotion) {
    return false;
  }

  return (
    promotion.page === activePromotion.page
    && promotion.zoneNumber === activePromotion.zoneNumber
    && promotion.title === activePromotion.title
  );
};

export const buildGridViewCellProperties = (props) => {
  const {
    columnOrder,
    columnsToCompare,
    editOverride,
    enableContextMenu = false,
    isContentWrappingEnabled = false,
    isMustFillHighlightEnabled = false,
    isReadOnly = false,
    isSelected = false,
    lastRevisionToCompare,
    popOverDirection = PopOverDirection.UP,
    promotion,
    shouldHighlight,
    shouldStylize,
    showSelectButtonOnly,
    storeRegionErrorMessages,
  } = props;
  const { isSoftDeleted } = promotion;
  return columnOrder.reduce((gridViewCellProperties, column, index) => {
    let errorText = (promotion.errors && promotion.errors[column.name] && promotion.errors[column.name].text) || '';
    // TODO: When errors are populated, add them to errorText.
    if (column.name === Columns.STORE_REGIONS.name && !showSelectButtonOnly) {
      const storeRegionErrorText = getStoreRegionErrors(promotion, storeRegionErrorMessages);
      if (storeRegionErrorText.length > 0) {
        errorText = storeRegionErrorText.join('\n\n');
      }
    }
    if (column.name === Columns.PICTURED_ASINS.name) {
      const asins = promotion[Columns.ASINS.name];
      const picturedAsins = promotion[column.name];
      if (asins && asins.length && !(picturedAsins && picturedAsins.length)) {
        errorText = Language.NO_PICTURED_ASINS;
      }
    }
    const gridViewCellProperty = {
      enableContextMenu,
      errorText,
      popOverDirection,
      columnName: column.name,
      inputType: column.inputType,
      isReadOnly: isReadOnly && !editOverride,
      isSticky: index <= 1,
      isContentWrappingEnabled,
      isMustFillHighlightEnabled,
      isSelected: isSelected && shouldStylize,
      key: `${promotion.id}-${column.name}`,
      name: column.name,
      promotion,
      rowId: promotion.id,
      showPercent: PercentageColumns.includes(column.name),
    };

    if (shouldHighlight && lastRevisionToCompare) {
      gridViewCellProperty.showComparison = columnsToCompare.includes(column.name);
      gridViewCellProperty.comparedItem = lastRevisionToCompare[column.name];
    }
    if (shouldStylize && isSoftDeleted) {
      gridViewCellProperty.highlightOverride = CellHighlightOverride.REMOVED;
    }

    return {...gridViewCellProperties, [column.name]: gridViewCellProperty}}, {}
  );
}

const mapStateToProps = (state, ownProps) => {
  const { promotion } = ownProps;
  const {
    GridViewPage: {
      activeCell,
    },
  } = state;
  const { id } = promotion;

  return {
    gridViewCellProperties: buildGridViewCellProperties(ownProps),
    isActive: activeCell && id === activeCell.rowId,
    isAdmin: isCurrentUserAdmin(state),
    isPromotionRelatedToActiveCell: isPromotionRelatedToActiveCell(state, activeCell, promotion),
  };
};

export default connect(mapStateToProps)(GridViewRow);
