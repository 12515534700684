import { InputTypes } from './index';

export const GridViewPopOverEnabledColumnTypes = Object.freeze([
  InputTypes.INPUT,
  InputTypes.ASIN_INPUT,
  InputTypes.TEXT_AREA_INPUT,
  InputTypes.MULTI_SELECT,
  InputTypes.BADGE_MULTI_SELECT,
  InputTypes.BADGE_TREE_SELECT,
]);
