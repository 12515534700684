import { connect } from 'react-redux';
import GridViewCell from './GridViewCell';
import {
  onSelectCell,
  onSetActiveCellEditMode,
} from '../../actionCreators';
import ActiveCellInputs from '../ActiveCellInputs';
import {
  InputTypes,
  Columns,
  MustFillColumns,
} from '../../constants';
import { GridViewPopOverEnabledColumnTypes } from '../../constants/popover';
import { isCurrentUserAdmin } from '../../selectors/user';
import { isPromotionTypeFixedPrice, isPromotionTypePercentOff } from '../../helpers/promotions';
import withStoreRegionCustomization from '../../decorators/withStoreRegionCustomization';
import withPicturedAsinsCustomization from '../../decorators/withPicturedAsinsCustomization';
import { isActiveCellEditMode } from '../../selectors/promotions';

export const mapStateToProps = (state, props) => {
  const {
    GridViewPage: { activeCell },
  } = state;
  const {
    promotion,
    rowId,
    columnName,
    inputType,
    isReadOnly,
    isMustFillHighlightEnabled,
  } = props;
  const isAdmin = isCurrentUserAdmin(state);

  const isCellActive = () => {
    if (!activeCell) {
      return false;
    }

    return rowId === activeCell.rowId
      && columnName === activeCell.columnName
  };

  const getWrappedInputComponent = (component) => {
    if (columnName === Columns.STORE_REGIONS.name) {
      return withStoreRegionCustomization(component);
    }
    if (columnName === Columns.PICTURED_ASINS.name) {
      return withPicturedAsinsCustomization(component, promotion);
    }

    return component;
  };

  const getInputComponent = () => {
    // TODO since there's no way to set an input type AND uneditable flag, we need to hack it here for now by @hanwooll
    // @vkryukov will build a proper configuration to replace the hack to support both input type and disabling
    // Disabled/uneditable column should be a global attribute somewhere, this is being modified in
    // middleware/Promo/resetPromotion.js, components/PromoForm/FormField/index.js, components/GridViewCell/index.js
    if ([
      Columns.PAGE.name,
      Columns.VERTICAL.name,
      Columns.VENDOR_MANAGER.name,
      Columns.ZONE_NUMBER.name,
    ].includes(columnName) && isAdmin) {
      return ActiveCellInputs.Input;
    }

    if ([
      Columns.IS_CATEGORY_HERO.name,
      Columns.IS_COVER_PAGE_HERO.name,
      Columns.IS_PRIVATE_BRAND.name,
    ].includes(columnName) && isAdmin) {
      return ActiveCellInputs.Checkbox;
    }
    // TODO: This hack should be fixed along with the functionality above
    if ([
      Columns.CIRCULAR_PRICE.name,
      Columns.PRIME_DISCOUNT.name,
      Columns.PRIME_MEMBER_LABEL.name,
    ].includes(columnName)
      && (isPromotionTypeFixedPrice(promotion[Columns.PROMOTION_TYPE.name]) || isPromotionTypePercentOff(promotion[Columns.PROMOTION_TYPE.name]))
    ) {
      return ActiveCellInputs.NumberInput;
    }

    if (isReadOnly) {
      return ActiveCellInputs.Uneditable;
    }

    switch (inputType) {
      case InputTypes.INPUT:
        return ActiveCellInputs.Input;
      case InputTypes.TEXT_AREA_INPUT:
        return ActiveCellInputs.TextAreaInput;
      case InputTypes.SINGLE_SELECT:
        return ActiveCellInputs.SingleSelect;
      case InputTypes.MULTI_SELECT:
        return ActiveCellInputs.MultiSelect;
      case InputTypes.BADGE_MULTI_SELECT:
        return ActiveCellInputs.MultiSelect;
      case InputTypes.BADGE_TREE_SELECT:
        return ActiveCellInputs.TreeSelect;
      case InputTypes.ASIN_INPUT:
        return ActiveCellInputs.AsinInput;
      case InputTypes.ASIN_TAG_INPUT:
        return ActiveCellInputs.AsinTagInput;
      case InputTypes.TAG_INPUT:
        return ActiveCellInputs.TagInput;
      case InputTypes.DATE_TIME:
        return ActiveCellInputs.DateTime;
      case InputTypes.TIME_INPUT:
        return ActiveCellInputs.TimeInput;
      case InputTypes.SINGLE_CHECKBOX:
        return ActiveCellInputs.Checkbox;
      case InputTypes.UNEDITABLE:
        return ActiveCellInputs.Uneditable;
      default:
        return ActiveCellInputs.Input;
    }
  };

  return {
    applyMustFillHighlight: isMustFillHighlightEnabled && MustFillColumns.includes(columnName),
    isPopOverEnabled: GridViewPopOverEnabledColumnTypes.includes(inputType),
    isEditMode: isActiveCellEditMode(state),
    isAdmin,
    isActive: isCellActive(),
    inputComponent: getWrappedInputComponent(getInputComponent()),
    link: '',
  };
};

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSelectCell() {
      const {
        rowId, columnName, promotion, isReadOnly,
      } = ownProps;
      if (isReadOnly) {
        return;
      }
      dispatch(onSelectCell({
        rowId,
        columnName,
      }));
    },
    onEditCell() {
      const { isReadOnly } = ownProps;
      if (isReadOnly) {
        return;
      }
      dispatch(onSetActiveCellEditMode(true));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(GridViewCell);
