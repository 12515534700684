import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DiscountTag } from '../../constants'
import GridViewCell from '../GridViewCell';
import styles from './GridViewRow.module.scss';
import { campaignColumnProps, campaignProps } from '../../proptypes';

export const getRowColorStyle = (props, editOverride) => {
  const { isActive, isReadOnly, isPromotionRelatedToActiveCell, promotion, shouldStylize } = props;
  const { discountTag, isPrimeBenefit } = promotion;
  if ((!!isReadOnly && !editOverride)) {
    return styles.locked;
  }
  if (!shouldStylize) {
    return styles.default;
  }
  if (isActive) {
    return styles.active;
  }

  if (isPromotionRelatedToActiveCell) {
    if (isPrimeBenefit) {
      return styles.highlightedPrimeBenefit;
    }
    if (discountTag === DiscountTag.EDLP) {
      return styles.highlightedEdlp;
    }
    return styles.highlight;
  }

  if (isPrimeBenefit) {
    return styles.primeBenefit;
  }
  if (discountTag === DiscountTag.EDLP) {
    return styles.edlp;
  }

  return styles.default;
};

export const getRowStyles = (props, editOverride) => {
  const { isSelected, isSticky, promotion, shouldStylize } = props
  const { isSoftDeleted } = promotion;
  const rowStyles = [];
  rowStyles.push(getRowColorStyle(props, editOverride));

  if (shouldStylize) {
    if (isSoftDeleted) {
      rowStyles.push(styles.crossedOut);
    }

    if (isSelected) {
      rowStyles.push(styles.isSelected);
    }
  }

  if (isSticky) {
    rowStyles.push(styles.isSticky);
  }
  return rowStyles;
};

const GridViewRow = (props) => {
  const {
    columnOrder,
    customActionBar: ActionBar = null,
    gridViewCellProperties,
    isAdmin,
    isSelected,
    isSelectionAllowed,
    promotion,
    showActionBar,
    showSelectButtonOnly,
  } = props;
  // feature to override locks and enable edits temporarily (for admins only)
  const [editOverride, setEditOverride] = useState(false);
  const toggleEditOverride = () => setEditOverride(!editOverride);
  const [width, setWidth] = useState(0);
  const firstColumn = useRef(null);

  useEffect(() => {
    setWidth(firstColumn.current.offsetWidth);
  }, [firstColumn.current]);

  const getActionBar = () => {
    if (!showActionBar || !ActionBar) {
      return null;
    }

    return (
      <td>
        <ActionBar
          promotion={promotion}
          editOverride={editOverride}
          toggleEditOverride={toggleEditOverride}
          isAdmin={isAdmin}
          isSelected={isSelected}
          isSelectionAllowed={isSelectionAllowed}
          showSelectButtonOnly={showSelectButtonOnly}
        />
      </td>
    )
  };

  const cells = columnOrder.map((column, index) => {
    const gridViewCellProperty = gridViewCellProperties[column.name];

    if (index === 0) {
      gridViewCellProperty.ref = firstColumn;
    }
    if (index === 1) {
      gridViewCellProperty.left = width;
    }

    return (
      <GridViewCell {...gridViewCellProperty}>
        {promotion[column.name]}
      </GridViewCell>
    );
  });

  const actionBar = getActionBar();

  return (
    <tr className={classnames(styles.row, getRowStyles(props, editOverride))}>
      {actionBar}
      {cells}
    </tr>
  );
};

GridViewRow.propTypes = {
  columnOrder: campaignColumnProps,
  columnsToCompare: PropTypes.arrayOf(PropTypes.string),
  enableContextMenu: PropTypes.bool,
  isActive: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isSelected: PropTypes.bool,
  isSelectionAllowed: PropTypes.bool,
  isSticky: PropTypes.bool,
  lastRevisionToCompare: campaignProps,
  promotion: campaignProps.isRequired,
  isPromotionActive: PropTypes.bool,
  showActionBar: PropTypes.bool,
  showSelectButtonOnly: PropTypes.bool,
  isContentWrappingEnabled: PropTypes.bool,
  isMustFillHighlightEnabled: PropTypes.bool,
};

GridViewRow.defaultProps = {
  columnOrder: [],
  columnsToCompare: [],
  isActive: false,
  enableContextMenu: false,
  isAdmin: false,
  isReadOnly: false,
  isSelected: false,
  isSelectionAllowed: true,
  isSticky: false,
  lastRevisionToCompare: {},
  isPromotionActive: false,
  showSelectButtonOnly: false,
  showActionBar: false,
  isContentWrappingEnabled: false,
  isMustFillHighlightEnabled: false,
};

export default GridViewRow;
